.container {
    width: 100vw;
    display: flex;
    height: 100vh;
    position: absolute;
    background-color: white;
    z-index: 100;
    left: 0px;
    overflow: hidden;
    background-color: var(--background-color);
}
.container::after {
    content: "";
    background: linear-gradient(180deg, #2945FF 0%, #6579FB 100%);
    width: 28%;
    height: 48%;
    border-radius: 100%;
    position: absolute;
    right: -160px;
    top: -300px;
}
.container::before {
    content: "";
    background: linear-gradient(180deg, rgba(41, 69, 255, 0.65) 0%, rgba(105, 125, 255, 0.65) 100%);
    width: 28%;
    height: 48%;
    border-radius: 100%;
    position: absolute;
    right: -220px;
    top: -250px;
}
.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.header {
    display: flex;
    align-items: center;
}
.head {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #333;
}
.loginContainer {
    width: 35vw;
    max-width: 550px;
    border-radius: 12px;
    padding: 40px 50px;
    height: auto;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
}
.loginContent {
    padding: 30px 40px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    display: flex;
    flex-direction: column; 
} 
.loginHead {
    font-size: 22px;
    font-weight: 500; 
    align-self: flex-start;
    color: #333;
    padding: 10px 0px;  
}
.textField {
    margin: 15px 0px !important;
    z-index: 1;
}
.signIn {
    margin: 10px 0px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 14px 18px;
    padding-right: 25px;
    font-size: 16px;
    width: 240px;
    justify-content: space-between;
    font-weight: 400;
    border: none;
    letter-spacing: 0.2px;
    border-radius: 20px;
    outline: none;
    overflow: hidden;
    color: white;
    background-color: var(--primary-color);
    position: relative;
    transition: .3s all;
}
.signIn:hover {
    cursor: pointer;
    transform: translateY(-1.5px);
}
.signIn:active {
    cursor: pointer;
    transform: translateY(0.5px);
}
.signIn::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    top: -50px;
    right: -70px;
}
.signIn::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    top: -85px;
    right: -10px;
}

@media only screen and (max-width: 1400px) {
    .container::after {
        right: -100px;
        top: -200px;
    }
    .container::before {
        right: -140px;
        top: -150px;
    }
}