* {
    outline: none !important;
    margin: 0;
    padding: 0px;
    font-family: 'Poppins' !important;
}
:root {
    --primary-color: #2743FD;
    
    --primary-color-light: rgba(39, 67, 253, 0.75);;
    
    --background-color: white;
    --text-color: #333;
    
    --heading: 18px;
    --sub-heading: 16px;
    --content: 14px;

    --nav-width: 300px;
    --breadcrump-height: 45px;

    --user-stoke-color: #1F78B4;
    --orders-stoke-color: #9c00aa;
    --ordersDelv-stoke-color: #65ff57;
}

body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    width: 4px;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    width: 4px;
}

/* GRAPH COLORS */
.ct-series-a .ct-line {
    stroke: var(--user-stoke-color); 
    stroke-width: 3px; 
}
.ct-series-a .ct-point {
    stroke: var(--user-stoke-color);  
    stroke-width: 6px; 
}
.ct-series-a .ct-area {
    fill: var(--user-stoke-color);  
} 

.ct-series-b .ct-line {
    stroke: var(--orders-stoke-color); 
    stroke-width: 3px; 
}
.ct-series-b .ct-point {
    stroke: var(--orders-stoke-color);  
    stroke-width: 6px; 
}
.ct-series-b .ct-area {
    fill: var(--orders-stoke-color);   
}

.ct-series-c .ct-line {
    stroke: var(--ordersDelv-stoke-color); 
    stroke-width: 3px; 
}
.ct-series-c .ct-point {
    stroke: var(--ordersDelv-stoke-color);
    stroke-width: 6px; 
}
.ct-series-c .ct-area {
    fill: var(--ordersDelv-stoke-color);
    stroke-width: 6px; 
}

.ct-chart-donut .ct-label {
    color: white;
    fill: white;
    text-shadow: 1px 5px 15px rgba(0, 0, 0);
    font-size: 14px;
    text-transform: capitalize;
}


.ct-series-a .ct-slice-donut {
    stroke: #855CF8;
}
.ct-series-b .ct-slice-donut {
    stroke: #ACB9FF;
}
.ct-series-c .ct-slice-donut {
    stroke: #7709CE;
}
.ct-series-d .ct-slice-donut {
    stroke: #E289F2;
}
.ct-series-e .ct-slice-donut {
    stroke: #e77b0056;
}
.ct-series-f .ct-slice-donut {
    stroke: #ff87abab;
}
.ct-series-g .ct-slice-donut {
    stroke: #316685;
}
.ct-series-h .ct-slice-donut {
    stroke: #00afb9;
}
.ct-series-i .ct-slice-donut {
    stroke: #800e13;
}
/* END GRPAH LINE COLORS */

p {
    font-size: 14px;
    color: #333;
}
@media only screen and (max-width: 1450px) {
    :root {
        --nav-width: 220px;
    }
}
