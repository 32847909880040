.container {
    width: 650px;
    padding: 0px;
    border-radius: 40px;
    padding: 25px;
    position: relative;
}
.container .close {
    position: absolute;
    right: 15px;
    top: 15px;
    color: black;
}

.container h1 {
    font-size: 16px;
    color: #333; 
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.container svg:hover {
    cursor: pointer;
}
.row {
    display: flex;
    justify-content: space-between;
}
.col:nth-child(1) {
    flex: 2;
}
.col:nth-child(2) {
    flex: 2;
}
.col {
    padding-right: 15px;
}
.col p{
    width: 100%;
    margin: 4px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col span {
    color: rgb(122, 122, 122);
}

.table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 10px 0px;
}
.table th   {
    font-weight: 500;
    background-color: var(--primary-color);
    color: white;
}
.table td, th {
    border: 1px solid  rgb(224, 224, 224);
    text-align: left;
    padding: 8px;
    font-size: 14px;
}
  
.table tr:nth-child(even) {
    background-color:  rgb(240, 240, 240);
}
.rowStatus {
    margin: 15px 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.select {
    flex: 1 !important;
    margin: 10px 0px !important;
    margin-right: 20px !important;
    font-size: 14px !important;
}
.item{
    font-size: 13px !important;
} 
