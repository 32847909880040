.searchInput {
    width: 100%;
    border: none;
    height: 40px;
    font-size: 15px;
    font-family: 'Poppins' !important;
    border-bottom: 2px solid var(--primary-color);
}
.autoComplete {
    width: 500px;   
    display: flex !important;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 70px;
    z-index: 1000;
    background-color: white;
    color: #333 !important;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
} 
.loader {
    padding: 30px 0px;
}
.listItem {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .autoComplete {
        width: calc(100vw - 20px);
        left: 10px;
    }
    .searchInput {
        width: 100%;
        flex: 1;
    }
}