.container {
    width: 100%; 
    height: auto;
    margin: 15px;
}
.content {
    display: flex;
    flex-wrap: wrap; 
}
.imgContainer {
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border: 2px solid rgb(143, 143, 143);
    border-radius: 2px;
    height: 100px;
}
.imgContainer span {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    padding: 2px 7px;
    color: white;
    background-color: red;
}
.imgContainer span:hover {
    cursor: pointer;
}
.imgContainer img {
    width: 90%;
    height: 90%;
}
.inputContainer {
    width: 100px;
    height: 100px;
    position: relative;
    background-color: rgb(141, 141, 141);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 13px;
}
.inputContainer p {
    margin-top: 5px; 
    color: white;
}
.inputContainer input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.inputContainer input:hover {
    cursor: pointer;
}
.inputContainer:hover {
    cursor: pointer;
}