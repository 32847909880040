.container {
    width: calc(97% - 40px);
    margin: 0 auto;
    display: flex;
    padding: 20px;
    height: 84vh;
}  
.leftContent {
    flex: 2; 
    height: 100%;
    overflow-y: scroll;
}
.leftContent::-webkit-scrollbar {
    width: 0px;
} 
.leftContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} 
.leftContent::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
.leftContent h1 {
    font-size: 22px;
    margin-bottom: 20px;
    margin-left: 20px;
    font-weight: 500;
} 
.rightContent {
    flex: 8; 
}
.listItem ,
.activeListItem { 
    width: calc(100% - 60px);
    padding: 20px 20px;
    font-size: 14px;
}

.listItem:hover{
    background-color: rgba(32, 190, 77, 0.1);
    cursor: pointer;
    border-radius: 4px;
}
.activeListItem {
    width: calc(100% - 60px);
    padding: 20px 20px;
    font-size: 14px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 4px;
}
.searchBar {
    width: calc(100% - 20px);
}
.searchBar input {
    width: 100%;
    height: 40px;
    text-indent: 15px;
    border-radius: 14px;
    outline: none;
    border: none;
    border: 1px solid rgb(146, 146, 146);
    margin-bottom: 20px;
    font-weight: 400;
}

.selectUser{
    width: calc(100% - 20px);
    width: 100%;
    height: 40px;
    text-indent: 15px;
    border-radius: 14px;
    outline: none;
    margin-bottom: 30px;
    font-weight: 400;

}

.selectUserSelect{
    margin-top: 2px;
    margin-left: 20px;
        border: none;


}