.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    height: 100%;  
    justify-content: center;
}
.header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 1px 10px 20px rgba(51, 51, 51, 0.01);
    display: flex;
    align-items: center;
}
.header p {
    font-size: 14px;
    margin-left: 15px;
}
.content {
    width: 100%;
    height: calc(100% - 120px);
    overflow-y: scroll;
    display: flex;
    z-index: 100;
    flex-direction: column;
    justify-content: flex-start; 
    background-color: rgba(4, 65, 177, 0.1);
}
.content > :first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.content::-webkit-scrollbar {
    width: 0px;
} 
.content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} 
.content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
.leftContent {
    align-self: flex-start;
    background-color: white;
    border-radius: 20px 20px 20px 0px;
}
.rightContent {
    align-self: flex-end;
    color: white;
    border-radius: 20px 20px 0px 20px;
    background-color: var(--primary-color);
}
.leftContent,
.rightContent {
    padding: 14px 20px;
    margin: 10px
}
.footer {
    display: flex;
    position: absolute;
    bottom: 0; 
    align-items: center;
    width: 100%; 
    background-color: rgba(32, 190, 77, 0.1);
    height: 60px;
}
.footer input {
    flex: 1;
    text-indent: 15px;
    height: 50px;
    font-size: 14px;
    color: #333;
    margin: 0px 10px;
    border: none;
    background-color: #fff;
    border-radius: 20px;
    outline: none;
}
.footer button {
    background-color: var(--primary-color);
    border-radius: 20px;
    width: 120px;
    height: 50px; 
    border: none;
    color: white;
}
.dateTime {
    font-size: 10px;
    color: white;
    opacity: 0.8;
    
}