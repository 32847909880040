.container {
    width: 97%;
    margin: 0 auto;
}
.paper {
    padding: 0px 30px;
}
.paper h1 {
    font-size: 20px;
    padding-top: 20px;
    margin: 10px;
    font-weight: 500;
}
.row {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}
.catName {
    flex: 2;
    margin: 10px !important;
} 
.switch {
    margin: 10px !important;
}