.container {
    width: 97%;
    margin: 0 auto;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 60px;
}
.leftHeader {
    display: flex;
    align-items: center;
}
.leftHeader p {
    font-size: 14px;
    margin-right: 10px;
}
.rightHeader button {
    margin-left: 10px !important;
} 

.rightHeader {
    display: flex;
    height: 100%;
    align-items: center;
}
.search {
    width: 200px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
}

.custom_scrollbar {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #a8a8a8 #f5f5f5; /* Color of the thumb and track respectively */
  }
  
  /* For WebKit browsers (Chrome, Safari) */
  .custom_scrollbar::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }
  
  .custom_scrollbar::-webkit-scrollbar-thumb {
    background-color: #a8a8a8; /* Color of the thumb */
  }
  
  .custom_scrollbar::-webkit-scrollbar-track {
    background-color: #f5f5f5; /* Color of the track */
  }
  
  
  
  
