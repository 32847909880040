.container {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.users,
.orders,
.ordersDelv {
    width: 45%;
    border-radius: 4px;
    margin: 2.2%;  
    border: 1px solid rgb(224, 224, 224);
}
.users .ct-series-a .ct-line {
    stroke: var(--user-stoke-color) !important; 
    stroke-width: 3px !important;  
}
.users .ct-series-a .ct-point {
    stroke: var(--user-stoke-color) !important;  
    stroke-width: 6px; 
}
.orders .ct-series-a .ct-line {
    stroke: var(--orders-stoke-color) !important; 
    stroke-width: 3px; 
}
.orders .ct-series-a .ct-point {
    stroke: var(--orders-stoke-color) !important;  
    stroke-width: 6px; 
}

.ordersDelv .ct-series-a .ct-line {
    stroke: var(--ordersDelv-stoke-color) !important; 
    stroke-width: 3px; 
}
.ordersDelv  .ct-series-a .ct-point {
    stroke: var(--ordersDelv-stoke-color) !important;
    stroke-width: 6px; 
}