.container {
  width: 97%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.paper {
  height: calc(100vh - 180px);
  width: 100%;
  padding: 40px;
  padding-top: 0px;
}
.mapContent {
  height: calc(100% - 60px);
}
.headers {
  padding: 15px 0px;
  display: flex;
  align-items: center;
}
.label {
  display: flex;
  align-items: center;
}
.label p {
  margin-right: 10px;
  margin-left: 15px;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 1;
  display: flex;
  width: "100%";
  flex-direction: column;
  gap: 5px;
}
.popup img {
  width: 200px;
  height: 200px;
}
.popup h6 {
  font-size: 1rem;
}
